<ng-container *ngIf="loading">
  <i
    class="bwi bwi-spinner bwi-spin text-muted"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="sr-only">{{ "loading" | i18n }}</span>
</ng-container>
<form
  #form
  (ngSubmit)="submit()"
  [appApiAction]="formPromise"
  ngNativeValidate
  *ngIf="!loading && this.plans"
>
  <h2 class="mt-5">{{ "generalInformation" | i18n }}</h2>
  <div class="row" *ngIf="createOrganization">
    <div class="form-group col-6">
      <label for="name">{{ "organizationName" | i18n }}</label>
      <input id="name" class="form-control" type="text" name="Name" [(ngModel)]="name" required />
    </div>
  </div>
  <h2 class="mt-5">{{ "chooseYourPlan" | i18n }}</h2>
  <div *ngFor="let selectableProduct of selectableProducts" class="form-check form-check-block">
    <input
      class="form-check-input"
      type="radio"
      name="product"
      id="product{{ selectableProduct.product }}"
      [value]="selectableProduct.product"
      [(ngModel)]="product"
      (change)="changedProduct()"
    />
    <label class="form-check-label" for="product{{ selectableProduct.product }}">
      {{ selectableProduct.nameLocalizationKey | i18n }}
      <small class="mb-1">{{ selectableProduct.descriptionLocalizationKey | i18n: "1" }}</small>
      <ng-container
        *ngIf="selectableProduct.product === productTypes.Enterprise; else fullFeatureList"
      >
        <small>• {{ "includeAllTeamsFeatures" | i18n }}</small>
        <small *ngIf="selectableProduct.hasSelfHost">• {{ "onPremHostingOptional" | i18n }}</small>
        <small *ngIf="selectableProduct.hasSso">• {{ "includeSsoAuthentication" | i18n }}</small>
        <small *ngIf="selectableProduct.hasPolicies"
          >• {{ "includeEnterprisePolicies" | i18n }}</small
        >
      </ng-container>
      <ng-template #fullFeatureList>
        <small *ngIf="selectableProduct.product == productTypes.Free"
          >• {{ "limitedUsers" | i18n: selectableProduct.maxUsers }}</small
        >
        <small *ngIf="selectableProduct.product != productTypes.Free && selectableProduct.maxUsers"
          >• {{ "addShareLimitedUsers" | i18n: selectableProduct.maxUsers }}</small
        >
        <small *ngIf="!selectableProduct.maxUsers">• {{ "addShareUnlimitedUsers" | i18n }}</small>
        <small *ngIf="selectableProduct.maxCollections"
          >• {{ "limitedCollections" | i18n: selectableProduct.maxCollections }}</small
        >
        <small *ngIf="selectableProduct.maxAdditionalSeats"
          >• {{ "addShareLimitedUsers" | i18n: selectableProduct.maxAdditionalSeats }}</small
        >
        <small *ngIf="!selectableProduct.maxCollections"
          >• {{ "createUnlimitedCollections" | i18n }}</small
        >
        <small *ngIf="selectableProduct.baseStorageGb"
          >• {{ "gbEncryptedFileStorage" | i18n: selectableProduct.baseStorageGb + "GB" }}</small
        >
        <small *ngIf="selectableProduct.hasGroups">• {{ "controlAccessWithGroups" | i18n }}</small>
        <small *ngIf="selectableProduct.hasApi">• {{ "trackAuditLogs" | i18n }}</small>
        <small *ngIf="selectableProduct.hasDirectory"
          >• {{ "syncUsersFromDirectory" | i18n }}</small
        >
        <small *ngIf="selectableProduct.hasSelfHost">• {{ "onPremHostingOptional" | i18n }}</small>
        <small *ngIf="selectableProduct.usersGetPremium">• {{ "usersGetPremium" | i18n }}</small>
        <small *ngIf="selectableProduct.product != productTypes.Free"
          >• {{ "priorityCustomerSupport" | i18n }}</small
        >
      </ng-template>
      <span *ngIf="selectableProduct.product == productTypes.Free">{{ "freeForever" | i18n }}</span>
    </label>
  </div>
  <div *ngIf="singleOrgPolicyBlock" class="mt-4">
    <app-callout [type]="'error'">{{ "singleOrgBlockCreateMessage" | i18n }}</app-callout>
  </div>
  <div class="mt-4">
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
      <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span>{{ "submit" | i18n }}</span>
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()" *ngIf="showCancel">
      {{ "cancel" | i18n }}
    </button>
  </div>
</form>
